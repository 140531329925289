import {useEffect} from 'react';
import mzgc_logo_87x78 from '../../assets/img/_Pics_Eagles_and_MZGC/MZGC Logo - Perfect sharpness.jpeg';
// import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';
import { UilMapMarker, UilEnvelopeAlt, UilPhone, UilFacebook, UilWhatsapp, UilTelegram } from '@iconscout/react-unicons';
import Header from '../__public/__layouts/Header';
import Footer from '../__public/__layouts/Footer';
import { Helmet } from 'react-helmet';
import Nav from '../__public/__layouts/Nav';

const Contact = (props) => {
    useEffect(() => {
        document.title = `Contact ${props.title}`;
    });

    return(
        <>
        <Header />
        <Nav />

        <Helmet>
            {/* <link href="/reg/css/bootstrap.min.css" rel="stylesheet" /> */}
            {/* <link href="/reg/css/style.css" rel="stylesheet" /> */}
        </Helmet>
        
        <div className="wow fadeInUp bg-primary2 abt" data-wow-delay="0.1s">
            <section>
                <div className="about__data">
                    <br /><br />
                    <h2 className="section__title text-white text-center">Contact Us</h2>
                </div>
            </section>
        </div>    
        
        <section className="abt_eagles grid container abt">
            <div className="about__data">
                <h2 className="section__title">Contact Us</h2>

                <div className="contact__group">
                    <div className="contact__info">
                        <div className="contact__item">
                            <UilMapMarker className="contact__icon text-primary2" />

                            <div>
                                {/* <h3 className="contact__item-title">Headquarters</h3> */}
                                <h1 className="office subtitle text-primary2">Headquarters</h1>
                                <span className="contact__item-subtitle">21/23 William George Street,</span>
                                <span className="contact__item-subtitle">Calabar, Cross River State, Nigeria.</span>
                            </div>
                        </div>

                        <div className="contact__item">
                            <UilEnvelopeAlt className="contact__icon text-primary2" />

                            <div>
                            <h1 className="office subtitle text-primary2">Email us</h1>
                                <span className="contact__item-subtitle">nationalyouthfellowshipmzgc@gmail.com </span>
                                {/* <span className="contact__item-subtitle">info@eaglesofzion</span> */}
                            </div>
                        </div>

                        <div className="contact__item">
                            <UilPhone className="contact__icon text-primary2" />

                            <div>
                                <h1 className="office subtitle text-primary2">Phone No.</h1>
                                <span className="contact__item-subtitle">+(234) 803-854-7692</span>
                                <span className="contact__item-subtitle">+(234) 806-700-8283</span>
                            </div>
                        </div>

                        <div className="contact__item">
                            <h1 className="office subtitle text-primary2">Follow us</h1>
                            <div className="course__social">
                                <a href="https://www.facebook.com/EAGLESOFZION" className="course__social-link"><UilFacebook /></a>
                                <a href="https://chat.whatsapp.com/FjJpWmTAPYK7gMTTvgzjzp" className="course__social-link"><UilWhatsapp /></a>
                                <a href="https://t.me/nyfmzgcmeetingroom/1" className="course__social-link"><UilTelegram /></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3974.9637271151228!2d8.318500273720867!3d4.945683139487723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1067863d5d2cf211%3A0xeb4d3c9618aea1ea!2s21%2C%2023%20William%20George%20St%2C%20Calabar%20540281%2C%20Cross%20River!5e0!3m2!1sen!2sng!4v1726862966649!5m2!1sen!2sng" width="100%" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </section>

      <Footer />

      {/* <Zones /> */}

            {/* <section className="contact section">
                <div className="contact__container grid container">
                    <div className="contact__content">
                        <h1 className="section__title contact__title">Contact us</h1>
                        <p className="contact__subtitle">Please get in touch. We'd love to hear from you</p>

                        <form action="" className="contact__form">
                            <div className="contact__form-group">
                                <div className="contact__form-div">
                                    <input type="text" className="contact__form-input" placeholder="Enter your name" />
                                </div>
                                <div className="contact__form-div">
                                    <input type="email" className="contact__form-input" placeholder="Enter your email" />
                                </div>
                            </div>

                            <div className="contact__form-div">
                                <input type="text" className="contact__form-input" placeholder="Message title" />
                            </div>

                            <div className="contact__form-div">
                                <textarea className="contact__form-input" cols="30" rows="10" placeholder="Enter your message" />
                            </div>

                            <button className="button" style={{ cursor: 'pointer', border: 'none', padding: '1.24rem 1.75rem' }}>Send Message</button>
                        </form>
                    </div>
                    <div className="contact__group">
                        <div className="contact__info">
                            <div className="contact__item">
                                <UilMapMarker className="contact__icon" />

                                <div>
                                    <h3 className="contact__item-title">Headquarters</h3>
                                    <span className="contact__item-subtitle">21/23 William George Street,</span>
                                    <span className="contact__item-subtitle">Calabar, Cross River State, Nigeria.</span>
                                </div>
                            </div>

                            <div className="contact__item">
                                <UilEnvelopeAlt className="contact__icon" />

                                <div>
                                    <h3 className="contact__item-title">Email us</h3>
                                    <span className="contact__item-subtitle">nationalyouthfellowshipmzgc@gmail.com </span>
                                    <span className="contact__item-subtitle">info@eaglesofzion</span>
                                </div>
                            </div>

                            <div className="contact__item">
                                <UilPhone className="contact__icon" />

                                <div>
                                    <h3 className="contact__item-title">Phone</h3>
                                    <span className="contact__item-subtitle">+(234) 803-854-7692</span>
                                    <span className="contact__item-subtitle">+(234) 806-700-8283</span>
                                </div>
                            </div>

                            <div className="contact__item">
                                <h3 className="contact__social-title">Follow us</h3>
                                <div className="course__social">
                                    <a href="https://www.facebook.com/EAGLESOFZION" className="course__social-link"><UilFacebook /></a>
                                    <a href="https://chat.whatsapp.com/FjJpWmTAPYK7gMTTvgzjzp" className="course__social-link"><UilWhatsapp /></a>
                                    <a href="https://t.me/nyfmzgcmeetingroom/1" className="course__social-link"><UilTelegram /></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section> */}

        

        </>
    );
}
export default Contact;