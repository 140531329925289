
import { UilYoutube, UilLinkedin, UilEnvelope, UilPhone, UilWhatsapp } 
  from '@iconscout/react-unicons';

const CompanyBanner = (props) => {
  return (
    <div className="zyzics_company_banner zy-c2 zy-c1-xs">
        <div>
            <h1>{props.project} {props.company}</h1>
        </div> 
        <div className="zy-c2 contacts">
            <div>
                <h6>
                    <a href="tel:+2348021075169" style={{color: '#17224d'}}>
                        <UilPhone /> +234 802-107-5169
                    </a>
                </h6>
            </div>
            <div>
                <a href="https://www.youtube.com/@zyzics-it-training" className="zyzics__social-link"><UilYoutube /></a>
                <a href="https://wa.me/message/GMNIQ5YNLZRFD1" className="zyzics__social-link"><UilWhatsapp /></a>
                <a href="" className="zyzics__social-link"><UilEnvelope /></a>
                <a href="https://linkedin.com/company/zyzics" className="zyzics__social-link"><UilLinkedin /></a>
            </div>        
        </div>         
    </div>
  )
}

export default CompanyBanner
