import {Routes, Route} from 'react-router-dom';
// Public
import IT_Training_Page from './pages/IT_Training_Page';

import Conference_Registration from './pages/conference/Conference_Registration';
import Registration from './pages/member/Registration';
import Login from './pages/member/Login';
import Dashboard from './pages/member/Dashboard';
import Logout from './pages/member/Logout';
import ContentManagement from './pages/cms/ContentManagement';
// Add
import AddActivity from './pages/activity/AddActivity';
import AddZone from './pages/zone/AddZone';
import AddActivity_Gallery from './pages/activity/AddActivity_Gallery';
// Manage
import Manage_Conference from './pages/conference/Manage_Conference';
import ManageMembers from './pages/member/ManageMembers';
import ManageZones from './pages/zone/ManageZones';
import ManageActivities from './pages/activity/ManageActivities';
import ManageActivities_Gallery from './pages/activity/ManageActivities_Gallery';
// Profile/Detail
import DetailConference from './pages/conference/DetailConference';
import DetailMember from './pages/member/DetailMember';
import DetailZone from './pages/zone/DetailZone';
// Edit
import EditConference from './pages/conference/EditConference';
import EditMember from './pages/member/EditMember';
import EditZone from './pages/zone/EditZone';
// Delete
import DeleteConference from './pages/conference/DeleteConference';
import DeleteMember from './pages/member/DeleteMember';
import DeleteZone from './pages/zone/DeleteZone';

import ProtectedRoute from '../components/ProtectedRoute'; // Import the ProtectedRoute
import Contact from './pages/Contact';
import About from './pages/About';
import Zone from './pages/Zone';
import Home from './pages/Home';

const Main = () => {
  const project = 'Zyzics';
  const company =  'IT Training and Software Ltd.';
  // const ittraining_desc = 'Learn Your Dream IT Courses In Simple, Clear Steps'
  const softwaredev_desc = 'Software Development, Website Design, etc. for businesses, organizations, and individuals'

  // const slogan = "Youths' Ministry of Mount Zion Gospel Church, Inc. Nigeria.";
  // const title = `${project} - ${slogan}`;

  return (
    <>
      {/* <Header project={project} /> */}
      <Routes>
        {/* Public */}
        <Route path="/" element={<Home project={project} company={company} />} />
        
        <Route path="/it-training" element={<IT_Training_Page 
          project={project} company={company} />} 
        />

        <Route path="/about" element={<About />} />
        <Route path="/zone" element={<Zone />} />
        <Route path="/contact" element={<Contact />} />
        
        <Route path="/conference-registration" element={<Conference_Registration />} />
        <Route path="/registration" element={<Registration project={project} />} />
        <Route path="/login" element={<Login project={project} />} />
        {/* AUTH PAGES */}
        <Route path="/dashboard" element={<Dashboard project={project} />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/manage-conference" element={<Manage_Conference />} />
        <Route path="/manage-members" element={<ManageMembers />} />
        <Route path="/manage-zones" element={<ManageZones />} />
        <Route path="/manage-activities" element={<ManageActivities />} />
        <Route path="/add-activity-gallery" element={<AddActivity_Gallery />} />        
        <Route path="/manage-activities-gallery" element={<ManageActivities_Gallery />} />
        <Route path="/add-activity" element={<AddActivity />} />
        <Route path="/content-management" element={<ContentManagement />} />
        <Route path="/add-zone" element={<AddZone />} />

        {/* Profile/Detail */}
        <Route path="/conference-registration/:slug" element={<DetailConference />} />
        <Route path="/member/:slug" element={<DetailMember />} />
        <Route path="/zone/:slug" element={<DetailZone />} />

        {/* Edit */}
        <Route 
          path="/edit-conference-registration/:slug" 
          element={<ProtectedRoute element={EditConference} />} // Protect this route
        />
        <Route path="/edit-member/:slug" 
          element={<EditMember />} // Don't protect route so members can edit their profile
        />
        <Route 
          path="/edit-zone/:slug" 
          element={<ProtectedRoute element={EditZone} />} // Protect this route
        />

        {/* Delete */}
        <Route 
          path="/delete-conference-registration/:slug" 
          element={<ProtectedRoute element={DeleteConference} />} // Protect this route
        />
        <Route 
          path="/delete-member/:slug" 
          element={<ProtectedRoute element={DeleteMember} />} // Protect this route
        />
        <Route 
          path="/delete-zone/:slug" 
          element={<ProtectedRoute element={DeleteZone} />} // Protect this route
        />

        {/* DETAIL PAGES */}
        {/* <Route path="/course-category/:category_id" element={<CategoryDetail project={project} />} />
        <Route path="/course/:course_id" element={<CourseDetail project={project} />} />
        <Route path="/instructor/:instructor_id" element={<InstructorDetail project={project} />} />

        <Route path="/manage-courses" element={<ManageCourses />} />
        <Route path="/course-sample" element={<CourseSample project={project} />} /> */}
      </Routes>
      {/* <Footer project={project} slogan={slogan} /> */}
    </>
  );
}
export default Main;
