import { useEffect, useRef } from 'react';
import explainer from '../../assets/videos/product_explainer.mp4';
import Header from '../__public/__layouts/Header';
import Footer from '../__public/__layouts/Footer';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { UilSearch, UilArrowDown, UilCheck } from '@iconscout/react-unicons';
import Banner from '../__public/__sections/Banner';
import CompanyBanner from '../__public/__sections/CompanyBanner';

// import Zones from '../__public/__sections/Zones';

const Home = (props) => {
  useEffect(() => {
    document.title = `${props.project} - IT Training and Software Company`;
  }, []);

  return (
    <>
    <Helmet>
      <link href="/reg/css/bootstrap.min.css" rel="stylesheet" />
    </Helmet>

    <Header />

    <div className="zy-c1_2">
      <div className="zyzics-caption">
        {props.project}
      </div>

      <nav className="zy-nav nav-right">
        <Link to="/" className="">IT Training</Link>
        <Link to="/" className="">Software Development</Link>
        <Link to="/" className="">More</Link>
        <Link to="/contact" className="">Login</Link>
          {/* <Link to="/" className="">Zy-solutions</Link>
          <Link to="/" className="">Books</Link>
          <Link to="/" className="">IT Consulting</Link>
          <Link to="/contact" className="">Users Area</Link> */}
        <Link to="/search"><UilSearch title="Find a course, an IT solution, etc..." /></Link>
        <Link to="/conference-registration" className="zyzics-btn button__header">Contact Us</Link>
      </nav>
    </div>

    <CompanyBanner project={props.project} company={props.company} />
    <Banner />

    {/* <Footer project={props.project} company={props.company} /> */}
  </>
  );
}

export default Home;