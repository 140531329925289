import { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../configs/Config'; // Import your baseUrl here
import Header from '../__public/__layouts/Header';
import { Helmet } from 'react-helmet';
import Nav from '../__public/__layouts/Nav';
import { UilCheckCircle } from '@iconscout/react-unicons';
import Footer from '../__public/__layouts/Footer';

const Zone = () => {
    const [zones, setZones] = useState([]);
    const [selectedLetter, setSelectedLetter] = useState(null);
    const [availableLetters, setAvailableLetters] = useState([]);
  
    // Fetch zone data from the API
    useEffect(() => {
      axios.get(`${baseUrl}/zone/`)
        .then((response) => {
          const zoneData = response.data;
          setZones(zoneData);
  
          // Find which letters have zones
          const lettersWithZones = zoneData.map(zone => zone.name[0].toUpperCase());
          setAvailableLetters([...new Set(lettersWithZones)]); // Remove duplicates
        })
        .catch(error => {
          console.error(error);
          setZones([]);
        });
    }, []);
  
    // Function to filter zones based on the selected letter
    const filteredZones = selectedLetter
      ? zones.filter(zone => zone.name[0].toUpperCase() === selectedLetter)
      : [];
  
    return (
        <>
        <Header />
        <Nav />

        <Helmet>
            {/* <link href="/reg/css/bootstrap.min.css" rel="stylesheet" /> */}
            {/* <link href="/reg/css/style.css" rel="stylesheet" /> */}
        </Helmet>
        
        <div className="wow fadeInUp bg-primary2 abt" data-wow-delay="0.1s">
            <section>
                <div className="about__data">
                    <br /><br />
                    <h2 className="section__title text-white text-center">Our Zones</h2>
                </div>
            </section>
        </div>    
        
        <section className="abt_eagles grid container abt">
            <div className="about__data">
                <h2 className="section__title">Our Zones</h2>
                <p>Click on the active alphabets below to get the corresponding zones list</p><br />
  
                {/* Alphabet filter */}
                <div className="alphabet-filter">
                {Array.from(Array(26)).map((_, i) => {
                    const letter = String.fromCharCode(65 + i); // Generate A to Z
                    return (
                    <button
                        key={letter}
                        className={`alphabet-button ${selectedLetter === letter ? 'active' : ''}`}
                        onClick={() => setSelectedLetter(letter)}
                        disabled={!availableLetters.includes(letter)} // Disable letters with no zones
                    >
                        {letter}
                    </button>
                    );
                })}
                </div>
        
                {/* List of zones starting with the selected letter */}
                <div className="zone-list">
                {filteredZones.length > 0 ? (
                    filteredZones.map((zone, index) => (
                    <div key={index} className="zone-item">                        
                       
                        
                        <h4 className="text-primary2">
                            <UilCheckCircle className="contact__icon text-primary2" /> 
                                &nbsp; {zone.name}
                        </h4>
                        {/* <p>{zone.description}</p> */}
                    </div>
                    ))
                ) : (
                    selectedLetter && <p>No zones available for {selectedLetter}</p>
                )}
                </div>
            </div>
        </section>

        <Footer />
    </>
    );
};
export default Zone;
