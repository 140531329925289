import Header from '../__public/__layouts/Header';
import Nav from '../__public/__layouts/Nav';
import { useState, useEffect } from 'react';
import Banner from '../__public/__layouts/Banner';
import UpcomingEvent from '../__public/__sections/UpcomingEvent';
import ChurchPresident_Welcome from '../__public/__sections/ChurchPresident_Welcome';
import EaglesPresident_Welcome from '../__public/__sections/EaglesPresident_Welcome';
import EaglesExcos from '../__public/__sections/EaglesExcos';
import Activities from '../__public/__sections/Activities';
import Faqs from '../__public/__sections/Faqs';
import Footer from '../__public/__layouts/Footer';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// import Zones from '../__public/__sections/Zones';
import AboutEagles from '../__public/others/AboutEagles';
import AboutChurch from '../__public/others/AboutChurch';
import NextEvent from '../__public/others/NextEvent';

const About = (props) => {
    const [aboutComponent, setaboutComponent] = useState(<AboutEagles />);
    useEffect(() => {
        document.title = `About ${props.title}`;
    });

    const showAbout_Eagles = () => {
        setaboutComponent(<AboutEagles />)
    }
    const showAbout_Church = () => {
        setaboutComponent(<AboutChurch />)
    }
    const showNext_Event = () => {
        setaboutComponent(<NextEvent />)
    }

  return (
    <>
        <Header />
        <Nav />

        <Helmet>
            {/* <link href="/reg/css/bootstrap.min.css" rel="stylesheet" /> */}
            {/* <link href="/reg/css/style.css" rel="stylesheet" /> */}
        </Helmet>
        
        <div className="wow fadeInUp bg-primary2 abt" data-wow-delay="0.1s">
            <section>
                <div className="about__data">
                    <br /><br />
                    <h2 className="section__title text-white text-center">About Us</h2>
                </div>
            </section>
        </div>    
        
        <section className="abt_eagles grid container abt">
            <div className="about__data">
                {aboutComponent}
            </div>

            <div className="about__data abt grey-bg rightside">                
                <Link onClick={showAbout_Eagles} className="button button__header my-1">About Eagles</Link>
                <Link onClick={showAbout_Church} className="button button__header my-1">About the Church</Link>
                <Link onClick={showNext_Event} className="button button__header my-1">Next Event</Link>                                
            </div>
        </section>

      <Footer />

      {/* <Zones /> */}
  </>
  );
}

export default About;