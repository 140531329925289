import {useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';

const NextEvent = () => {
    const slug = 'upcoming-event';
  // const [data, setData] = useState([]);
  const [data, setData] = useState(null); // Use null to distinguish between loading and empty data
  const cacheKey = `upcoming-event-${slug}`; // Unique key for caching this page's data


  useEffect(() => {
    // Check if data is already in localStorage
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      setData(JSON.parse(cachedData)); // Set cached data immediately

    } else {
      axios.get(`${baseUrl}/cms/${slug}/`)
        .then(response => {
          console.log(response.data);
          setData({
            ...response.data
          });
          localStorage.setItem(cacheKey, JSON.stringify(response.data)); // Cache the data
        })
        .catch(error => {
          console.error("There was an error fetching the data!", error);
        });
    }
  }, [slug, cacheKey]);

  // If data is null or title is missing, don't render the section
  if (!data || !data.title) {
    return null; // Return nothing, so nothing is displayed until data is fetched
  }

  return (
    <>
    <h2 className="section__title">Next Event</h2>
    <h1 className="office subtitle text-primary2">{data.title}</h1><br /> 

    <div className="about__thumb">
          <img src={data.image} className="about__thumb-img full-image" alt="" />
        </div>
        <br />
    
    <h1 className="office subtitle">{data.title}</h1><br />

    <p>{data.content} ... Read more</p>
    <br />
    <p><Link to="/conference-registration" className="button button__header">Conference Registration</Link></p><br />
    <p><Link to="/registration" className="button button__header" style={{ border: '2px solid #fff', background:'transparent'}}>Member Registration</Link></p>
    
    </>
  )
}
export default NextEvent;
