import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import Swal from 'sweetalert2';
import Sidebar from '../../layouts/Sidebar';
import Header_Dashboard from '../../layouts/Header_Dashboard';
import Logout_Modal from '../../layouts/Logout_Modal';
// import Footer_Dashboard from '../../layouts/Footer_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';

const EditConference = () => {
  const { slug } = useParams();
  const [cats, setCats] = useState([]); 
  const [data, setData] = useState({   
    fname: '',
    sname: '',
    title: '',
    gender: '',
    phone: '',
    email: '',
    age_range: '', 
    address: '',
    origin: '',
    zone: '',
    position: '',
    mode_of_attendance: '',
    professional_session: '',
    payment_method: '',
    occupation: '',
    how_did_you_hear: '',
    talent: '',
    image_gallery: null  // Ensure this is initialized as null for the file
  });

  useEffect(() => {
    axios.get(`${baseUrl}/conference-registration/${slug}/`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the member data!", error);
      });
  }, [slug]);

  useEffect(() => {
    axios.get(`${baseUrl}/zone`)
        .then((response) => {
        setCats(response.data);
    })
    .catch(error => {
        setCats([]);
    });
  }, []);

  // change element value
  const handleChange = (e) => {
    if (e.target.name === 'image_gallery') {
      setData({
        ...data, 
        [e.target.name]: e.target.files[0]  // Use files[0] for the file input
      });
    } else {
      setData({
        ...data, 
        [e.target.name]: e.target.value
      });
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('fname', data.fname);
    formData.append('sname', data.sname);
    formData.append('title', data.title);
    formData.append('gender', data.gender);
    formData.append('phone', data.phone);
    formData.append('email', data.email);
    formData.append('age_range', data.age_range); 
    formData.append('address', data.address);
    formData.append('origin', data.origin);
    formData.append('zone', data.zone);
    formData.append('position', data.position);
    formData.append('mode_of_attendance', data.mode_of_attendance);
    formData.append('professional_session', data.professional_session);
    formData.append('payment_method', data.payment_method);
    formData.append('occupation', data.occupation);
    formData.append('how_did_you_hear', data.how_did_you_hear);
    formData.append('talent', data.talent);

    // Only append the image if it's provided
    if (data.image_gallery && data.image_gallery !== 'null') {
      formData.append('image', data.image_gallery);
    }

    axios.put(`${baseUrl}/conference-registration/${slug}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'  // Required for file uploads
        }
      })
      .then((response) => {
        successAlert();
      })
      .catch(error => {
        errorAlert(error);
      });
  };

  const successAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Conference Registration Updated!",
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      window.location.href = `/edit-conference-registration/${data.slug}`;
    });
  };

  const errorAlert = (error) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "An error occurred!",
      text: error.message,
      showConfirmButton: false,
      timer: 1500
    });
  };

  return (
    <>
    <Header_Dashboard />

    <div id="page-top">
      <div id="wrapper">
          <Sidebar />

          <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                  <ContentWrapper />
                  
                  <div className="container-fluid">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                      <h1 className="h3 mb-0 text-gray-800">Edit {data.fname} {data.sname}'s Info</h1>
                    </div>

                    <form onSubmit={submitForm} encType="multipart/form-data"> 

                    <div className="row">
                      <div className="col-xl-4 col-lg-5">
                          <div className="card shadow mb-4">
                            <div className="card-header py-3">
                              <h6 className="m-0 font-weight-bold">Photo</h6>
                            </div>
                            <div className="card-body">
                              <img
                                src={data.image}  // No need to concatenate the URL
                                alt={data.fname}
                                style={{ width: '100%', height: 'auto' }}
                              />

                              <h4 className="small font-weight-bold">Upload another image</h4>
                              <input 
                                  type="file" 
                                  name="image_gallery" 
                                  onChange={handleChange} 
                                  className="" 
                                  accept="image/*" 
                              />  
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-8 col-lg-7">

                          <div className="card shadow mb-4">
                              <div className="card-header bg-info text-white py-3 d-flex flex-row align-items-center justify-content-between">
                                  <h6 className="m-0 font-weight-bold">{data.fname} {data.sname}</h6>
                              </div>
                              <div className="card-body">
                                  <div className="row">
                                      <div className="col-12">                                        
                                          <div className="form-group row"> 

                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">First Name <span className="asterisk">*</span></h4>
                                                  <input name="fname" value={data.fname} onChange={handleChange} maxLength="30" 
                                                      className="form-control form-control-user" required /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Surname <span className="asterisk">*</span></h4>
                                                  <input name="sname" value={data.sname} onChange={handleChange} maxLength="30" 
                                                      className="form-control form-control-user" required /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Your Title</h4>
                                                  <input name="title" value={data.title} onChange={handleChange} maxLength="20" 
                                                      className="form-control form-control-user" /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Gender</h4>
                                                  <select name="gender" value={data.gender} className="form-control" 
                                                      onChange={handleChange}>
                                                      <option value="">- Select Your Gender -</option>
                                                      <option value="Female">Female</option>
                                                      <option value="Male">Male</option>
                                                  </select><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Phone No. <span className="asterisk">*</span></h4>
                                                  <input type="number" name="phone" value={data.phone} onChange={handleChange} maxLength="16" 
                                                      className="form-control form-control-user" required /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Email Address <span className="asterisk">*</span></h4>
                                                  <input type="email" name="email" value={data.email} onChange={handleChange} maxLength="50" 
                                                      className="form-control form-control-user" required /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                <h4 className="small font-weight-bold">Your Age Range </h4>
                                                <select name="age_range" value={data.age_range} className="form-control"  
                                                    onChange={handleChange}>
                                                    <option selected>- Select Age Range -</option>
                                                    <option value="Below 16">Younger than 16</option>
                                                    <option value="16-20">16-20</option>
                                                    <option value="21-30">21-30</option>
                                                    <option value="31-40">31-40</option>
                                                    <option value="40+">40+</option>
                                                </select><br />                                              
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Residential Address (City and State)</h4>
                                                  <input name="address" value={data.address} onChange={handleChange} maxLength="40" 
                                                      className="form-control form-control-user" />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">State of Origin</h4>
                                                  <select name="origin" value={data.origin} className="form-control" onChange={handleChange}>
                                                      <option value="">- Select Your Origin -</option>
                                                      <option value="Abia">Abia</option>
                                                      <option value="Akwa Ibom">Akwa Ibom</option>
                                                      <option value="Cross River">Cross River</option>
                                                      <option value="Lagos">Lagos</option>
                                                  </select><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Your Zone (if member)</h4>                                        
                                                  <select className="form-control" name="zone" 
                                                    value={data.zone} onChange={handleChange}>
                                                    <option value={cats.id}>{cats.find(category => category.id === data.zone)?.name || "Unknown Category"}</option>
                                                    
                                                    {cats.map((dt, index) => (
                                                      <option key={index} value={dt.id}>                                          
                                                        {dt.name}
                                                      </option>
                                                    ))} 
                                                  </select><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Executive Position (if member)</h4>
                                                  <input name="position" value={data.position} onChange={handleChange} maxLength="60" 
                                                      className="form-control form-control-user" /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Planned Attendance Mode</h4>
                                                  <select name="mode_of_attendance" value={data.mode_of_attendance} 
                                                    className="form-control" onChange={handleChange}>
                                                      <option value="">- Select Attendance Mode -</option>
                                                      <option value="Physical">Physical</option>                                               
                                                      <option value="Virtual">Virtual</option>
                                                  </select><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Professional session you wish to attend</h4>
                                                  <select name="professional_session" value={data.professional_session} 
                                                    className="form-control" onChange={handleChange}>
                                                      <option value="">- Select Professional Session -</option>
                                                      <option value="Agriculture">Agriculture</option> 
                                                      <option value="Business startup">Business startup</option> 
                                                      <option value="Creative design">Creative design</option>  
                                                      <option value="Digital marketing">Digital marketing</option>  
                                                      <option value="Legal">Legal</option> 
                                                      <option value="Soft-skill enhancement">Soft-skill enhancement</option> 
                                                  </select><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Payment Method</h4>
                                                  <select name="payment_method" value={data.payment_method} className="form-control"  
                                                      onChange={handleChange}>
                                                      <option value="">- Select Payment Method -</option>
                                                      <option value="Bank Transfer">Bank Transfer</option> 
                                                      <option value="Card">Card</option> 
                                                      <option value="Cash">Cash</option> 
                                                  </select><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Occupation</h4>
                                                  <input name="occupation" value={data.occupation} onChange={handleChange} maxLength="30" 
                                                      className="form-control form-control-user" /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">How did you hear about GOTE?</h4>
                                                  <select name="how_did_you_hear" value={data.how_did_you_hear} 
                                                    className="form-control" onChange={handleChange}>
                                                      <option value="">- Select how you heard -</option>
                                                      <option value="Church">Church</option>
                                                      <option value="Friend">Friend</option> 
                                                      <option value="Social media">Social media</option>
                                                      <option value="Online search">Online search</option> 
                                                      <option value="Other event">Other event</option>
                                                  </select><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Do you have any special talent?</h4>
                                                  <input name="talent" value={data.talent} onChange={handleChange} maxLength="30" 
                                                      className="form-control form-control-user" />
                                              </div>                        
                                          </div>
                                          <hr />
                                          <button type="submit" className="btn btn-primary btn-user">
                                            Update Conference Reg
                                          </button>

                                          {/* <button type="submit" className="btn btn-info btn-user">
                                              Is National Exco
                                          </button>
                                          <button type="submit" className="btn btn-success btn-user mx-3">
                                              Make Admin
                                          </button> */}
                                      </div>
                                  </div>
                              </div>
                          </div>                          
                        </div>                        
                    </div>

                    </form> 
                </div>

              </div>
      
              {/* <Footer_Dashboard /> */}
          </div>
      </div>
      <Logout_Modal />
    </div>
    </>
  );
};
export default EditConference;             