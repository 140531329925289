import {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../__public/__layouts/Header';
import Nav from '../__public/__layouts/Nav';
import { UilYoutube, UilLinkedin, UilEnvelope, UilPhone, UilSearch, UilWhatsapp, UilArrowDown, UilCheck } from '@iconscout/react-unicons';
import eagles_logo from '../../assets/img/logos/eagles_logo_removebg.png';

const IT_Training_Page = (props) => {
    useEffect(() => {
        document.title = `${props.project} - IT Training and Software Development`;
    }, []);

    return (
        <>
        
        <Header />
        {/* <Nav project={props.project} /> */}

        <Helmet>
            <link href="/reg/css/bootstrap.min.css" rel="stylesheet" />
            <link href="/reg/css/style.css" rel="stylesheet" />
        </Helmet>
        
        <div className="zy-nav-wrap zy-c2">
            <div className="brand zy-nav">
                {props.project}
            </div>
            <nav className="zy-nav nav-right nav-addons">
                <Link to="/" className="">IT Courses</Link>
                <Link to="/" className="">What We Do</Link>
                <Link to="/" className="">Work Samples</Link>
                <Link to="/contact" className="">Users Portal</Link>
                <Link to="/search"><UilSearch title="Find a course, an IT solution, etc..." /></Link>
                <Link to="/conference-registration" className="button button__header">Contact Us</Link>
            </nav>
        </div>

        <div className="page_banner">
            <h1>{props.title} </h1>
        </div>

        <div className="zy-nav-wrap zy-c2">
            <div className="zy-nav nav-right black">&nbsp;</div>

            <div className="brand"></div>
        </div>

        <div className="company_banner wow fadeInUp zy-c2" data-wow-delay="0.1s">
            <div className="company">
                <h3>{props.project} {props.company}</h3>
                RC 7921450
            </div>
                <div className=" grid">
                    <div className="zy-nav visitwebsite">
                        <Link to="/" className="">
                            <span className="zyzics__social-link" title="Send an Email to Zyzics" alt="Send an Email to Zyzics">
                                <UilEnvelope />
                            </span><br />
                            Software Development <br />
                            <p className="zyzics__social-link">Visit Website</p>
                        </Link>
                        <Link to="/" className="">
                        <span className="zyzics__social-link" title="Send an Email to Zyzics" alt="Send an Email to Zyzics">
                                <UilEnvelope />
                            </span><br />
                            Zy - Solutions <br />
                            <p className="zyzics__social-link">Visit Website</p>
                        </Link>
                        
                        <div className="zyzics__social2">
                            <a href="" className="zyzics__social-link" title="Subscribe to Zyzics on YouTube" alt="Subscribe to Zyzics on YouTube">
                                <UilYoutube />
                            </a>
                            <a href="" className="zyzics__social-link" title="Chat with Zyzics on WhatsApp" alt="Chat with Zyzics on WhatsApp">
                                <UilWhatsapp />
                            </a>
                            <a href="" className="zyzics__social-link" title="Send an Email to Zyzics" alt="Send an Email to Zyzics">
                                <UilEnvelope />
                            </a>
                        </div>
                    </div>
                </div>
        </div>

        {/* 
        <div className="company_banner wow fadeInUp zy-c2" data-wow-delay="0.1s">
            <div className="company">
                <h3>{props.company}</h3>
            </div>

            <div className="sites grid">
                <div className="about__courses">
                    <Link to="/">
                        <div className="about__course-item item-2">
                            <h4 className="about__course-title" style={{color: 'black'}}>
                            IT Training </h4>
                            <h6>You are here <br />
                            <UilArrowDown /></h6>
                        </div>
                    </Link>
                </div>
                
                <div className="about__courses">
                    <Link to='/conference-registration'>
                        <div className="about__course-item item-1">
                            <h4 className="about__course-title" style={{color: 'black'}}>Software Devt.</h4>
                            <h6>Visit Website</h6>
                        </div>
                    </Link>
                </div>

                <div className="about__courses">
                    <Link to='/conference-registration'>
                        <div className="about__course-item item-3">
                            <h4 className="about__course-title" style={{color: 'black'}}>e-Solutions</h4>
                            <h6>Visit Website</h6>
                        </div>
                    </Link>
                </div>
            </div>
        </div> */}

        {/* 
        <div className="it-training-section2">
            <h1>Section 2</h1>

            <div className="zy-c6 zy-c4-md zy-c3-sm zy-c2-xs">
                <div>One</div>
                <div>Two</div>
                <div>Three</div>
                <div>Four</div>
                <div>Five</div>
                <div>Six</div>
                <div>Seven</div>
            </div> 
        </div> */}

        {/* From black, it shd transcend gracefully into a lighter black (see ferrari website) */}
        <div className="it-training-section3">
            {/* <h1>Section 3</h1> */}
        </div>

        <div className="zyzics__social">
            Connect with us &nbsp; 

            <a href="" className="zyzics__social-link"><UilYoutube /></a>
            <a href="" className="zyzics__social-link"><UilLinkedin /></a>
            <a href="" className="zyzics__social-link"><UilPhone /></a>
            <a href="" className="zyzics__social-link"><UilEnvelope /></a>
        </div>
        
        
        </>
    )
}
export default IT_Training_Page;