import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import Swal from 'sweetalert2';
import Sidebar from '../../layouts/Sidebar';
import Header_Dashboard from '../../layouts/Header_Dashboard';
import Logout_Modal from '../../layouts/Logout_Modal';
// import Footer_Dashboard from '../../layouts/Footer_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';

const EditMember = () => {
  const { slug } = useParams();
  const [cats, setCats] = useState([]); 
  const [data, setData] = useState({      
    zone: '',
    title: '',
    fname: '',
    sname: '',
    phone: '',
    email: '',
    gender: '',
    section: '',
    district: '',
    assembly: '',
    nationalexco_position: '',
    zonalexco_position: '',
    age_range: '',
    image_gallery: null  // Ensure this is initialized as null for the file
  });

  useEffect(() => {
    axios.get(`${baseUrl}/member/${slug}/`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the member data!", error);
      });
  }, [slug]);

  useEffect(() => {
    axios.get(`${baseUrl}/zone`)
        .then((response) => {
        setCats(response.data);
    })
    .catch(error => {
        setCats([]);
    });
  }, []);

  // change element value
  const handleChange = (e) => {
    if (e.target.name === 'image_gallery') {
      setData({
        ...data, 
        [e.target.name]: e.target.files[0]  // Use files[0] for the file input
      });
    } else {
      setData({
        ...data, 
        [e.target.name]: e.target.value
      });
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('zone', data.zone);
    formData.append('title', data.title);
    formData.append('fname', data.fname);
    formData.append('sname', data.sname);
    formData.append('phone', data.phone);
    formData.append('email', data.email);
    formData.append('gender', data.gender);
    formData.append('section', data.section);
    formData.append('district', data.district);
    formData.append('assembly', data.assembly);
    formData.append('nationalexco_position', data.nationalexco_position);
    formData.append('zonalexco_position', data.zonalexco_position);
    formData.append('age_range', data.age_range);
    formData.append('is_nationalexco', data.is_nationalexco);
    formData.append('is_admin', data.is_admin);

    // Only append the image if it's provided
    if (data.image_gallery && data.image_gallery !== 'null') {
      formData.append('image', data.image_gallery);
    }

    axios.put(`${baseUrl}/member/${slug}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'  // Required for file uploads
        }
      })
      .then((response) => {
        successAlert();
      })
      .catch(error => {
        errorAlert(error);
      });
  };

  const successAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Member updated successfully!",
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      window.location.href = `/edit-member/${data.slug}`;
    });
  };

  const errorAlert = (error) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "An error occurred!",
      text: error.message,
      showConfirmButton: false,
      timer: 1500
    });
  };

  return (
    <>
    <Header_Dashboard />

    <div id="page-top">
      <div id="wrapper">
          <Sidebar />

          <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                  <ContentWrapper />
                  
                  <div className="container-fluid">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                      <h1 className="h3 mb-0 text-gray-800">Edit {data.title} {data.fname} {data.sname}'s Info</h1>
                    </div>

                    <form onSubmit={submitForm} encType="multipart/form-data"> 

                    <div className="row">
                      <div className="col-xl-4 col-lg-5">
                          <div className="card shadow mb-4">
                            <div className="card-header py-3">
                              <h6 className="m-0 font-weight-bold">Photo</h6>
                            </div>
                            <div className="card-body">
                              <img
                                src={data.image}  // No need to concatenate the URL
                                alt={data.fname}
                                style={{ width: '100%', height: 'auto' }}
                              />

                              <h4 className="small font-weight-bold">Upload another image</h4>
                              <input 
                                  type="file" 
                                  name="image_gallery" 
                                  onChange={handleChange} 
                                  className="" 
                                  accept="image/*" 
                              />  
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-8 col-lg-7">

                          <div className="card shadow mb-4">
                              <div className="card-header bg-info text-white py-3 d-flex flex-row align-items-center justify-content-between">
                                  <h6 className="m-0 font-weight-bold">{data.title} {data.fname} {data.sname}</h6>
                              </div>
                              <div className="card-body">
                                  <div className="row">
                                      <div className="col-12">                                        
                                          <div className="form-group row"> 
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Your Zone <span className="asterisk">*</span></h4>                                        
                                                  <select className="form-control" name="zone" 
                                                    value={data.zone} onChange={handleChange}>
                                                    <option value={cats.id}>{cats.find(category => category.id === data.zone)?.name || "Unknown Category"}</option>
                                                    
                                                    {cats.map((dt, index) => (
                                                      <option key={index} value={dt.id}>                                          
                                                        {dt.name}
                                                      </option>
                                                    ))} 
                                                  </select><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Your Title</h4>
                                                  <input name="title" value={data.title} onChange={handleChange} maxLength="20" 
                                                      className="form-control form-control-user" /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">First Name <span className="asterisk">*</span> 
                                                    (CANNOT be changed)</h4>
                                                  <input name="fname" value={data.fname} onChange={handleChange} maxLength="30" 
                                                      className="form-control form-control-user" readOnly /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Surname <span className="asterisk">*</span></h4>
                                                  <input name="sname" value={data.sname} onChange={handleChange} maxLength="30" 
                                                      className="form-control form-control-user" required /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Phone No. <span className="asterisk">*</span></h4>
                                                  <input type="number" name="phone" value={data.phone} onChange={handleChange} maxLength="16" 
                                                      className="form-control form-control-user" required />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Email Address <span className="asterisk">*</span></h4>
                                                  <input type="email" name="email" value={data.email} onChange={handleChange} maxLength="50" 
                                                      className="form-control form-control-user" required /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Gender</h4>
                                                  <select name="gender" value={data.gender} className="form-control" 
                                                    onChange={handleChange}>
                                                      <option value="">- Select Your Gender -</option>
                                                      <option value="Female">Female</option>
                                                      <option value="Male">Male</option>
                                                  </select><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Section</h4>
                                                  <select name="section" value={data.section} className="form-control" 
                                                      onChange={handleChange}>
                                                      <option value="">- Select Your Section -</option>
                                                      <option value="Abak">Abak</option>
                                                      <option value="Anua">Anua</option>
                                                      <option value="Calabar">Cross River</option>
                                                      <option value="Eket">Ikot Ekpene</option>
                                                      <option value="Idu">Idu</option>
                                                      <option value="Ikom">Ikom</option>
                                                      <option value="Ikot Ekpene">Ikot Ekpene</option>
                                                      <option value="Lagos">Lagos</option>
                                                      <option value="Port Harcourt">Port Harcourt</option>
                                                      <option value="Uyo">Uyo</option>
                                                      <option value="Others">Others</option>
                                                  </select><br />
                                              </div>

                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Your District </h4>
                                                  <input type="text" name="district" value={data.district} onChange={handleChange} maxLength="30" 
                                                      className="form-control form-control-user" /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Your Assembly </h4>
                                                  <input type="text" name="assembly" value={data.assembly} onChange={handleChange} maxLength="30" 
                                                      className="form-control form-control-user" /><br />
                                              </div>                                
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Your National Executive Position</h4>
                                                  <input name="nationalexco_position" value={data.nationalexco_position} onChange={handleChange} maxLength="30" 
                                                      className="form-control form-control-user" /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Your Zonal Executive Position</h4>
                                                  <input name="zonalexco_position" value={data.zonalexco_position} onChange={handleChange} maxLength="30" 
                                                      className="form-control form-control-user" /><br />
                                              </div>
                                              <div className="col-12 col-sm-6">
                                                  <h4 className="small font-weight-bold">Your Age Range </h4>
                                                  <select name="age_range" value={data.age_range} className="form-control"
                                                      onChange={handleChange}>
                                                      <option selected>- Select Age Range -</option>
                                                      <option value="Below 16">Younger than 16</option>
                                                      <option value="16-20">16-20</option>
                                                      <option value="21-30">21-30</option>
                                                      <option value="31-40">31-40</option>
                                                      <option value="40+">40+</option>
                                                  </select>                                              
                                              </div>
                                          </div>
                                          <hr />
                                          <input type="hidden" name="is_admin" value={data.is_admin} />
                                          <input type="hidden" name="is_nationalexco" value={data.is_nationalexco} />

                                          <button type="submit" className="btn btn-primary btn-user">
                                            Update Profile
                                          </button>

                                          {/* <button type="submit" className="btn btn-info btn-user">
                                              Is National Exco
                                          </button>
                                          <button type="submit" className="btn btn-success btn-user mx-3">
                                              Make Admin
                                          </button> */}
                                      </div>
                                  </div>
                              </div>
                          </div>                          
                        </div>                        
                    </div>

                    </form> 
                </div>

              </div>
      
              {/* <Footer_Dashboard /> */}
          </div>
      </div>
      <Logout_Modal />
    </div>
    </>
  );
};
export default EditMember;

{/* <div className="user" onSubmit={submitForm}>
  
      <input name="name" value={data.fname} onChange={handleChange} maxLength="50" 
        className="form-control form-control-user" placeholder="Name of Zone" required />
   */}
                          