import {useState, useEffect} from 'react';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import Nav from '../../__public/__layouts/Nav';
import Header from '../../__public/__layouts/Header';

const Registration = (props) => {
    const[data, setData] = useState({
        'zone': '',
        'title': '',
        'fname': '',
        'sname': '',
        'phone': '',
        'email': '',
        'password': '',
        'gender': '',
        'section': '',
        'district': '',
        'assembly': '',
        'nationalexco_position': '',
        'zonalexco_position': '',
        'age_range': '',
        'image_gallery': null  // Ensure this is initialized as null for the file
    });
    const [zones, setZones] = useState([]); // categories
    
    useEffect(() => {
        document.title = `${props.project} | Member Registration`;
    });

    // change element value
    const handleChange = (e) => {
        if (e.target.name === 'image_gallery') {
            setData({
                ...data, 
                [e.target.name]: e.target.files[0]  // Use files[0] for the file input
            });
        } else {
            setData({
                ...data, 
                [e.target.name]: e.target.value
            });
        }
    };

    useEffect(() => {
        axios.get(`${baseUrl}/zone/`)
          .then((response) => {
            setZones(response.data);
          })
          .catch(error => {
            setZones([]);
          });
    }, []);

    const submitForm = (e) => {
        e.preventDefault();

        // Validate the form
        if (e.target.checkValidity()) {
            const formData = new FormData();

            formData.append('zone', data.zone)
            formData.append('title', data.title)
            formData.append('fname', data.fname)
            formData.append('sname', data.sname)
            formData.append('phone', data.phone)
            formData.append('email', data.email)        
            formData.append('password', data.password)
            formData.append('gender', data.gender)
            formData.append('section', data.section) 
            formData.append('district', data.district) 
            formData.append('assembly', data.assembly)
            formData.append('nationalexco_position', data.nationalexco_position) 
            formData.append('zonalexco_position', data.zonalexco_position)
            formData.append('age_range', data.age_range) 

            // Only append the image if it's provided
            if (data.image_gallery && data.image_gallery !== 'null') {
                formData.append('image', data.image_gallery);
            }
            // formData.append('role', data.role)

            axios.post(`${baseUrl}/member/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'  // Required for file uploads
                }
            })            
            .then((response) => {
                setData({
                    'zone': '',
                    'title': '',
                    'fname': '',
                    'sname': '',
                    'phone': '',
                    'email': '',
                    'password': '',
                    'gender': '',
                    'section': '',
                    'district': '',
                    'assembly': '',
                    'nationalexco_position': '',
                    'zonalexco_position': '',
                    'age_range': '',
                    'image_gallery': null,
                    // 'role': ''
                });
                console.log(response.data)
                successAlert();
            })
            .catch(error => {
                setData({
                    // 'status': 'error'
                });
                errorAlert(error);
            });
        } else {
            e.target.reportValidity();  // Triggers browser's built-in validation
        }
    }

    const loggedInStatus = localStorage.getItem('loggedInStatus')
    if (loggedInStatus == 'true'){
        window.location.href = '/dashboard';
    }

    const successAlert = (response) => {
        return(
            Swal.fire({            
                // position: "bottom-end",
                position: "center",
                icon: "success",
                title: "Membership registration is successful!",
                showConfirmButton: true,
                // timer: 1500
            }).then(function() {            
                window.location.href = '/login'
            })           
        )
    }
    const errorAlert = (error) => {
        Swal.fire({
            position: "center",
            icon: "error",
            title: "An error occurred!",
            text: error.message,
            showConfirmButton: false,
            timer: 1500
        });              
    }

    return (
        <>
        <Header />
        <Nav />
        
        <Helmet>
            <link href="/reg/css/bootstrap.min.css" rel="stylesheet" />
            <link href="/reg/css/style.css" rel="stylesheet" />
        </Helmet>

        <div className="video wow fadeInUp bg-primary2" data-wow-delay="0.1s" style={{ height:'75vh'}}>
            <br />
            <p className="text-center small mb-2 text-white">Click a link of your choice below to register or login.</p>
            <h1 className="mb-2 text-warning text-center reglogin">
                <Link to="/conference-registration" className="reglogin_headerlinks">Conference Registration (GOTE 2024)</Link>
            </h1>
            <h1 className="mb-2 text-center reglogin">
                <Link to="/registration" className="reglogin_headerlinks active">Membership Registration</Link>
            </h1>
            <h3 className="text-white mb-1 reglogin text-center">
                <Link to="/login" className="reglogin_headerlinks">Member Login</Link>
            </h3>
        </div>

        <div className="container position-relative wow fadeInUp" data-wow-delay="0.1s" style={{ marginTop:'-6rem' }}>
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="bg-light p-5">
                        <h1 className="text-center m-0">Membership Registration</h1>
                        <p className="text-center small mb-4">Required fields are marked with Asterisk *</p>

                        <form onSubmit={submitForm}>
                            <div className="row g-3 text-left">
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Your Zone <span className="asterisk">*</span></h4>                                        
                                    <select name="zone" onChange={handleChange} className="form-select border-0" 
                                        style={{ height:'55px' }} required>  

                                        <option value="">- Choose Your Zone -</option>
                                        {zones.map(zone => (
                                            <option key={zone.id} value={zone.id}>{zone.name}</option>
                                        ))}
                                    </select> 
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Your Title</h4>
                                    <input name="title" onChange={handleChange} maxLength="20" 
                                        className="form-control border-0" placeholder="E.g. Bro., Sis., Deacon, Elder, Evang." 
                                        style={{ height:'55px' }} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">First Name <span className="asterisk">*</span></h4>
                                    <input name="fname" onChange={handleChange} maxLength="30" 
                                        className="form-control border-0" placeholder="Your First Name" 
                                        style={{ height:'55px' }} required />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Surname <span className="asterisk">*</span></h4>
                                    <input name="sname" onChange={handleChange} maxLength="30" 
                                        className="form-control border-0" placeholder="Your Surname" 
                                        style={{ height:'55px' }} required />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Phone No. <span className="asterisk">*</span></h4>
                                    <input type="number" name="phone" onChange={handleChange} maxLength="16" 
                                        className="form-control border-0" placeholder="E.g. 08098765432" 
                                        style={{ height:'55px' }} required />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Email Address <span className="asterisk">*</span></h4>
                                    <input type="email" name="email" onChange={handleChange} maxLength="50" 
                                        className="form-control border-0" placeholder="E.g. myname@mail.com" 
                                        style={{ height:'55px' }} required />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Create a Password <span className="asterisk">*</span></h4>
                                    <input type="password" name="password" onChange={handleChange} maxLength="100" 
                                        className="form-control border-0" placeholder="A secret code you can remember ..." 
                                        style={{ height:'55px' }} required />                                
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Gender</h4>
                                    <select name="gender" className="form-select border-0" style={{ height:'55px' }} 
                                        onChange={handleChange}>
                                        <option value="">- Select Your Gender -</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Section</h4>
                                    <select name="section" className="form-select border-0" style={{ height:'55px' }} 
                                        onChange={handleChange}>
                                        <option value="">- Select Your Section -</option>
                                        <option value="Abak">Abak</option>
                                        <option value="Anua">Anua</option>
                                        <option value="Calabar">Cross River</option>
                                        <option value="Eket">Ikot Ekpene</option>
                                        <option value="Idu">Idu</option>
                                        <option value="Ikom">Ikom</option>
                                        <option value="Ikot Ekpene">Ikot Ekpene</option>
                                        <option value="Lagos">Lagos</option>
                                        <option value="Port Harcourt">Port Harcourt</option>
                                        <option value="Uyo">Uyo</option>
                                        <option value="Others">Others</option>
                                    </select> 
                                </div>

                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Your District </h4>
                                    <input type="text" name="district" onChange={handleChange} maxLength="30" 
                                        className="form-control border-0" placeholder="E.g. Uyo" 
                                        style={{ height:'55px' }} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Your Assembly </h4>
                                    <input type="text" name="assembly" onChange={handleChange} maxLength="30" 
                                        className="form-control border-0" placeholder="E.g. Aka Itiam" 
                                        style={{ height:'55px' }} />
                                </div>                                
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Your National Executive Position</h4>
                                    <input name="nationalexco_position" onChange={handleChange} maxLength="30" 
                                        className="form-control border-0" placeholder="E.g. Secretary" 
                                        style={{ height:'55px' }} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Your Zonal Executive Position</h4>
                                    <input name="zonalexco_position" onChange={handleChange} maxLength="30" 
                                        className="form-control border-0" placeholder="E.g. Provost" 
                                        style={{ height:'55px' }} />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Your Age Range </h4>
                                    <select name="age_range" className="form-select border-0" style={{ height:'55px' }} 
                                        onChange={handleChange}>
                                        <option selected>- Select Age Range -</option>
                                        <option value="Below 16">Younger than 16</option>
                                        <option value="16-20">16-20</option>
                                        <option value="21-30">21-30</option>
                                        <option value="31-40">31-40</option>
                                        <option value="40+">40+</option>
                                    </select>                                                    
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h4 className="small font-weight-bold">Upload Your Photograph</h4>
                                    <input 
                                        type="file" 
                                        name="image_gallery" 
                                        onChange={handleChange} 
                                        className="" 
                                        accept="image/*"
                                    />
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-success w-100 py-3">
                                        Register as Member
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Registration;