import { useState, useEffect } from 'react';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../../__public/__layouts/Header';
import Nav from '../../__public/__layouts/Nav';

const Login = (props) => {
    const [data, setData] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        document.title = `${props.project} | Member Login`;
    
        const loggedInStatus = localStorage.getItem('loggedInStatus');
        if (loggedInStatus === 'true') {
            // window.location.href = '/dashboard';
            window.location.href = `${window.location.origin}/dashboard`;
        }
    }, [props.project]);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('email', data.email);
        formData.append('password', data.password);

        axios.post(`${baseUrl}/login/`, formData)
            .then((response) => {
                if (response.data.bool === true) {
                    localStorage.setItem('loggedInStatus', true); // gets logged in status
                    localStorage.setItem('memberId', response.data.member_id); // gets user id
                    localStorage.setItem('fname', response.data.fname); // Save first name
                    localStorage.setItem('sname', response.data.sname); // Save last name
                    localStorage.setItem('isAdmin', response.data.is_admin);  // Save admin status
                    
                    // window.location.href = '/dashboard';
                    window.location.href = `${window.location.origin}/dashboard`;
                } else {
                    alert('Invalid credentials');
                }
            })
            .catch((error) => {
                console.error('There was an error!', error);
            });
    }

    const loggedInStatus = localStorage.getItem('loggedInStatus');
    if (loggedInStatus === 'true') {
        // window.location.href = '/dashboard';
        window.location.href = `${window.location.origin}/dashboard`;
    }

    return (
        <>
        <Header />
        <Nav />
        
        <Helmet>
            <link href="/reg/css/bootstrap.min.css" rel="stylesheet" />
            <link href="/reg/css/style.css" rel="stylesheet" />
        </Helmet>

        <div className="video wow fadeInUp bg-primary2" data-wow-delay="0.1s" style={{ height:'75vh'}}>
            <br />
            <p className="text-center small mb-2 text-white">Click a link of your choice below to register or login.</p>
            <h1 className="mb-2 text-warning text-center reglogin">
                <Link to="/conference-registration" className="reglogin_headerlinks">Conference Registration (GOTE 2024)</Link>
            </h1>
            <h1 className="mb-2 text-center reglogin">
                <Link to="/registration" className="reglogin_headerlinks">Membership Registration</Link>
            </h1>
            <h3 className="text-white mb-1 reglogin text-center">
                <Link to="/login" className="reglogin_headerlinks active">Member Login</Link>
            </h3>
        </div>

        <div className="container position-relative wow fadeInUp" data-wow-delay="0.1s" style={{ marginTop:'-6rem' }}>
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="bg-light p-5">
                        <h1 className="text-center m-0">Login as Member</h1>
                        <p className="text-center small mb-4">Required fields are marked with Asterisk *</p>

                        <form onSubmit={submitForm}>
                            <div className="row g-3 text-left">
                                <div className="col-12">
                                    <h4 className="small font-weight-bold">Your Email Address <span className="asterisk">*</span></h4>
                                    <input type="email" name="email" onChange={handleChange} maxLength="50" 
                                        className="form-control border-0" placeholder="E.g. myname@mail.com" 
                                        style={{ height:'55px' }} required />
                                </div>
                                <div className="col-12">
                                    <h4 className="small font-weight-bold">Your Password <span className="asterisk">*</span></h4>
                                    <input type="password" name="password" onChange={handleChange} maxLength="100" 
                                        className="form-control border-0" placeholder="A secret code you can remember ..." 
                                        style={{ height:'55px' }} required />                                
                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-success w-100 py-3">
                                        Log into user Dashboard
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

                // <div className="col-12 mb-3">
                //     <h4 className="small font-weight-bold">Email Address</h4>
                //     <input name="email" onChange={handleChange} value={data.email} type="email" 
                //         className="form-control" maxLength="60" id="exampleInputEmail" 
                //         aria-describedby="emailHelp" placeholder="Enter Email Address..." required />
                // </div>
            
                // <div className="col-12 mb-3">
                //     <h4 className="small font-weight-bold">Password</h4>                                            
                //     <input name="password" onChange={handleChange} value={data.password} type="password" 
                //         className="form-control" maxLength="100" id="exampleInputPassword" 
                //         placeholder="Password" required />
                // </div>
                // <div className="col-12 mb-3">
                //     <button type="submit" onClick={submitForm} className="btn btn-primary btn-user btn-block">
                //         Login
                //     </button>
                // </div>
export default Login;
