import eagles_logo from '../../../assets/img/logos/eagles_logo.jpg'

const AboutEagles = () => {
  return (
    <>
    <h2 className="section__title">About Us</h2>

    <h1 className="office subtitle text-primary2">Our Mission</h1><br /> 
    <p>Join us for the "GOTE 2024 Youth Conference": A transformative gathering designed 
        to inspire and equip young believers with the tools to lead lives of faith and 
        purpose. This conference will be held at the church auditorium international 
        headquarters
    </p><br />

    <h1 className="office subtitle text-primary2">Our Vision</h1><br /> 
    <p>Join us for the "GOTE 2024 Youth Conference": A transformative gathering designed 
        to inspire and equip young believers with the tools to lead lives of faith and 
        purpose. This conference will be held at the church auditorium international 
        headquarters
    </p><br />
    
    <h1 className="office subtitle text-primary2">Another Header</h1><br /> 
    <p>Join us for the "GOTE 2024 Youth Conference": A transformative gathering designed 
        to inspire and equip young believers with the tools to lead lives of faith and 
        purpose. This conference will be held at the church auditorium international 
        headquarters
    </p><br />

    <h1 className="office subtitle text-primary2">Yet Another Header</h1><br /> 
    <p>Join us for the "GOTE 2024 Youth Conference": A transformative gathering designed 
        to inspire and equip young believers with the tools to lead lives of faith and 
        purpose. This conference will be held at the church auditorium international 
        headquarters
    </p>
    
    <img src={eagles_logo} title="Eagles of Zion Logo" className="about__thumb-img" alt="eagles_logo" />
    <br />
    </>
  )
}

export default AboutEagles
