import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { baseUrl } from '../../configs/Config';
import axios from 'axios';
import Sidebar from '../../layouts/Sidebar';
import Header_Dashboard from '../../layouts/Header_Dashboard';
import Footer_Dashboard from '../../layouts/Footer_Dashboard';
import ContentWrapper from '../../layouts/ContentWrapper';
import Logout_Modal from '../../layouts/Logout_Modal';
// import { Link } from 'react-router-dom';

const DetailMember = () => {
    let { slug } = useParams();
    const [member, setMember] = useState(null);
    const [cats, setCats] = useState([]); // categories
    const [isAdmin, setIsAdmin] = useState(false);
    
    const [showConfirm, setShowConfirm] = useState(false);
    const [actionToConfirm, setActionToConfirm] = useState(null); // Holds the action like 'is_nationalexco' or 'is_admin'


    useEffect(() => {
        const adminStatus = localStorage.getItem('isAdmin') === 'true';
        setIsAdmin(adminStatus);
    }, []);

    useEffect(() => {
        axios.get(`${baseUrl}/member/${slug}/`)
        .then(response => {
            setMember(response.data);
        })
        .catch(error => {
            console.error('There was an error fetching the member!', error);
        });
    }, [slug]);
    
    useEffect(() => {
        axios.get(`${baseUrl}/zone/`)
            .then((response) => {
            setCats(response.data);
        })
        .catch(error => {
            setCats([]);
        });
    }, []);

    if (!member) {
        return <div>Loading...</div>;
    }

    const updateMemberStatus = (fieldToUpdate) => {
        const url = `${baseUrl}/member/${slug}/`;
        
        let data = {};
        if (fieldToUpdate === 'is_nationalexco') {
            data = { is_nationalexco: 1 }; // Add nationalexco_position
        } else if (fieldToUpdate === 'is_admin') {
            data = { is_admin: 1 };
        }
    
        axios.patch(url, data)
          .then(response => {
            console.log('Update successful:', response.data);
            alert(`${fieldToUpdate} updated successfully`);
          })
          .catch(error => {
            console.error('There was an error updating the member!', error);
            alert('Error updating member');
          });
      };
    
    
    const handleShowConfirmation = (action) => {
        setActionToConfirm(action); // Set the specific action to be confirmed
        setShowConfirm(true); // Show the confirmation dialog
    };

    const handleConfirmAction = () => {
        if (actionToConfirm) {
            updateMemberStatus(actionToConfirm); // Call the action update based on the choice
        }
        setShowConfirm(false); // Hide the confirmation dialog
    };

    const handleCancel = () => {
        setShowConfirm(false); // Close the dialog without any action
    };
      

  return (
    <>
    <Header_Dashboard />

      <div id="page-top">
        <div id="wrapper">
            <Sidebar />

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <ContentWrapper />
                    
                    <div className="container-fluid">
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">{member.title} {member.fname} {member.sname}'s Profile</h1>
                    </div>

                      <div className="row">

                        <div className="col-xl-4 col-lg-5">
                            <div className="card shadow mb-4">
                              <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold">Photo</h6>
                              </div>
                              <div className="card-body">
                                    <img
                                        src={member.image}  // No need to concatenate the URL
                                        alt={member.fname}
                                        style={{ width: '100%', height: 'auto' }}
                                    />   
                                </div>
                            </div>
                          </div>

                          <div className="col-xl-8 col-lg-7">

                            <div className="card shadow mb-4">
                                <div className="card-header bg-primary text-white py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="m-0 font-weight-bold">{member.title} {member.fname} {member.sname}</h6>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">                                        
                                            <div className="form-group row">
                                                <div className="col-6">
                                                    <h4 className="small font-weight-bold">Title</h4>
                                                    <h5 className="m-0">{member.title}</h5><br />
                                                </div> 
                                                <div className="col-6 mb-3 mb-sm-0">
                                                    <h4 className="small font-weight-bold">Full Name</h4>
                                                    <h5 className="m-0">{member.fname} {member.sname}</h5><br />
                                                </div>
                                                <div className="col-6">
                                                    <h4 className="small font-weight-bold">Gender / Age Range</h4>
                                                    <h5 className="m-0">{member.gender} / {member.age_range}</h5><br />
                                                </div>    
                                                <div className="col-6 mb-3 mb-sm-0">
                                                    <h4 className="small font-weight-bold">Phone No.</h4>
                                                    <h5 className="m-0">{member.phone}</h5><br />
                                                </div>
                                                <div className="col-6">
                                                    <h4 className="small font-weight-bold">Email Address</h4>
                                                    <h5 className="m-0">{member.email}</h5><br />
                                                </div> 
                                                <div className="col-6 mb-3 mb-sm-0">
                                                    <h4 className="small font-weight-bold">Zone</h4>
                                                    <h5 className="m-0">{cats.find(category => category.id === member.zone)?.name || "Unknown Category"}</h5><br />
                                                </div>
                                                <div className="col-6">
                                                    <h4 className="small font-weight-bold">Section</h4>
                                                    <h5 className="m-0">{member.section}</h5><br />
                                                </div>  
                                                <div className="col-6 mb-3 mb-sm-0">
                                                    <h4 className="small font-weight-bold">District</h4>
                                                    <h5 className="m-0">{member.district}</h5><br />
                                                </div>
                                                <div className="col-6">
                                                    <h4 className="small font-weight-bold">Assembly</h4>
                                                    <h5 className="m-0">{member.assembly}</h5><br />
                                                </div>   
                                                <div className="col-6 mb-3 mb-sm-0">
                                                    <h4 className="small font-weight-bold">National Executive Position</h4>
                                                    <h5 className="m-0">{member.nationalexco_position}</h5><br />
                                                </div>
                                                <div className="col-6">
                                                    <h4 className="small font-weight-bold">Zonal Executive Position</h4>
                                                    <h5 className="m-0">{member.zonalexco_position}</h5><br />
                                                </div>                                   
                                            </div>

                                            {isAdmin ? (
                                            <>
                                                {isAdmin && (
                                                    <>
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-info btn-user"
                                                            onClick={() => handleShowConfirmation('is_nationalexco')}>
                                                            Confirm as National Exco
                                                        </button>

                                                        <button 
                                                            type="button" 
                                                            className="btn btn-success btn-user mx-3"
                                                            onClick={() => handleShowConfirmation('is_admin')}>
                                                            Appoint as Web Admin
                                                        </button>
                                                    </>
                                                )}

                                                {showConfirm && (
                                                    <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title">Confirm Action</h5>
                                                                    <button type="button" className="close" onClick={handleCancel}>
                                                                        <span>&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <p>Are you sure you want to proceed with this action?</p>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button className="btn btn-success" onClick={handleConfirmAction}>
                                                                        Yes, proceed
                                                                    </button>
                                                                    <button className="btn btn-secondary" onClick={handleCancel}>
                                                                        No, cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                            ) : (
                                                <></>
                                            )}

                                            
                                            {/* <Link to="" className="btn btn-secondary btn-user mx-2">Preview</Link> */}

                                            {/* <button type="" className="btn btn-success btn-user float-right">
                                                PUBLISH AS FINAL
                                            </button> */}
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                          </div>                          
                      </div>
                  </div>

                </div>
        
                {/* <Footer_Dashboard /> */}
            </div>
        </div>
        <Logout_Modal />
      </div>
    </>
  );
};
export default DetailMember;            