import mzgc_logo from '../../../assets/img/_Pics_Eagles_and_MZGC/MZGC Logo - Perfect sharpness.jpeg'

const AboutChurch = () => {
  return (
    <>
    <h2 className="section__title">About the Church</h2>
 
    <img src={mzgc_logo} title="Mount Zion Gospel Church Logo" className="about__thumb-img" alt="mzgc_logo" />
    <br />
    
    <h1 className="office subtitle text-primary2">Motto</h1><br /> 
    <p>In God we trust</p><br />

    <h1 className="office subtitle text-primary2">Founded/Established</h1><br /> 
    <p>Join us for the "GOTE 2024 Youth Conference": A transformative gathering designed
    </p><br />

    <h1 className="office subtitle text-primary2">Mission</h1><br /> 
    <p>Join us for the "GOTE 2024 Youth Conference": A transformative gathering designed 
        to inspire and equip young believers with the tools to lead lives of faith and 
        purpose. This conference will be held at the church auditorium international 
        headquarters
    </p><br />

    <h1 className="office subtitle text-primary2">Vision</h1><br /> 
    <p>Join us for the "GOTE 2024 Youth Conference": A transformative gathering designed 
        to inspire and equip young believers with the tools to lead lives of faith and 
        purpose. This conference will be held at the church auditorium international 
        headquarters
    </p><br />

    <h1 className="office subtitle text-primary2">Beliefs</h1><br /> 
    <p>Join us for the "GOTE 2024 Youth Conference": A transformative gathering designed 
        to inspire and equip young believers with the tools to lead lives of faith and 
        purpose. This conference will be held at the church auditorium international 
        headquarters
    </p><br />
    
    <h1 className="office subtitle text-primary2">Another Header</h1><br /> 
    <p>Join us for the "GOTE 2024 Youth Conference": A transformative gathering designed 
        to inspire and equip young believers with the tools to lead lives of faith and 
        purpose. This conference will be held at the church auditorium international 
        headquarters
    </p><br />

    <h1 className="office subtitle text-primary2">Yet Another Header</h1><br /> 
    <p>Join us for the "GOTE 2024 Youth Conference": A transformative gathering designed 
        to inspire and equip young believers with the tools to lead lives of faith and 
        purpose. This conference will be held at the church auditorium international 
        headquarters
    </p>
    </>
  )
}

export default AboutChurch
